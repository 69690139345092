import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import { Divider, FormHelperText, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Applicant } from '../../../types/applicant.type';
import { useFieldArray, useForm } from 'react-hook-form';

export const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

type IProps = {
  setData: (user: Applicant) => void;
};

const AddressForm: FC<IProps> = ({ setData }) => {
  const [title, setTitle] = useState<string>('');
  const [initials, setInitials] = useState<string>('');
  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [id_number, setIdNumber] = useState<string>('');
  const [date_of_birth, setDateOfBirth] = useState<string>('');
  const [occupation, setOccupation] = useState<string>('');
  const [contact_number, setContactNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address_line_1, setAddressLine1] = useState<string>('');
  const [address_line_2, setAddressLine2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postal_code, setPostalCode] = useState<string>('');
  const [emergency_name, setEmergencyName] = useState<string>('');
  const [emergency_contact_number, setEmergencyNumber] = useState<string>('');
  const [emergency_relation_to, setEmergencyRelationTo] = useState<string>('');
  const [medical_aid_name, setMedicalAidName] = useState<string>('');
  const [medical_aid_number, setMedicalAidNumber] = useState<string>('');

  useEffect(() => {
    setData({
      title,
      initials,
      first_name,
      last_name,
      id_number,
      date_of_birth,
      occupation,
      contact_number,
      email,
      address_line_1,
      address_line_2,
      city,
      postal_code,
      medical_aid_name,
      medical_aid_number,
      emergency_contact_name: emergency_name,
      emergency_contact_number,
      emergency_contact_relation: emergency_relation_to
    });
  }, [
    title,
    initials,
    first_name,
    last_name,
    id_number,
    date_of_birth,
    occupation,
    contact_number,
    email,
    address_line_1,
    address_line_2,
    city,
    postal_code,
    emergency_name,
    emergency_contact_number,
    emergency_relation_to,
    medical_aid_name,
    medical_aid_number
  ]);

  return (
    <Grid container spacing={3}>
      <FormGrid size={{ xs: 12 }}>
        <Divider>
          <Typography variant="h4">Personal Details</Typography>
        </Divider>
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 3 }}>
        <FormLabel htmlFor="title" required>
          Title
        </FormLabel>
        <OutlinedInput
          id="title"
          name="title"
          type="text"
          placeholder="Mr"
          autoComplete="title"
          required
          size="small"
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 3 }}>
        <FormLabel htmlFor="initials" required>
          Initials
        </FormLabel>
        <OutlinedInput
          id="initials"
          name="initials"
          type="text"
          placeholder="ab"
          autoComplete="initials"
          required
          size="small"
          onChange={(e) => setInitials(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="id_number" required>
          ID Number
        </FormLabel>
        <OutlinedInput
          id="id_number"
          name="id_number"
          type="text"
          placeholder="123456789"
          autoComplete="id-number"
          required
          size="small"
          onChange={(e) => setIdNumber(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="first_name" required>
          First name
        </FormLabel>
        <OutlinedInput
          id="first_name"
          name="first_name"
          type="name"
          placeholder="John"
          autoComplete="first name"
          required
          size="small"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="last_name" required>
          Last name
        </FormLabel>
        <OutlinedInput
          id="last_name"
          name="last_name"
          type="last_name"
          placeholder="Snow"
          autoComplete="last name"
          required
          size="small"
          onChange={(e) => setLastName(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="date_of_birth" required>
          Date of Birth
        </FormLabel>
        <OutlinedInput
          id="date_of_birth"
          name="date_of_birth"
          type="date"
          autoComplete="birthday"
          required
          size="small"
          onChange={(e) => setDateOfBirth(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="occupation" required>
          Occupation (if applicable)
        </FormLabel>
        <OutlinedInput
          id="occupation"
          name="occupation"
          type="text"
          autoComplete="occupation"
          size="small"
          onChange={(e) => setOccupation(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="contact_number" required>
          Contact number
        </FormLabel>
        <OutlinedInput
          id="contact_number"
          name="contact_number"
          type="text"
          required
          size="small"
          onChange={(e) => setContactNumber(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="email" required>
          Email address
        </FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="text"
          autoComplete="email"
          required
          size="small"
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <Divider sx={{ marginTop: 3 }} textAlign="left">
          <Typography variant="h5">Address</Typography>
        </Divider>
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="address_line_1" required>
          Address line 1
        </FormLabel>
        <OutlinedInput
          id="address_line_1"
          name="address_line_1"
          type="text"
          placeholder="Street name and number"
          autoComplete="shipping-address-line1"
          required
          size="small"
          onChange={(e) => setAddressLine1(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="address_line_1">Address line 2</FormLabel>
        <OutlinedInput
          id="address_line_2"
          name="address_line_2"
          type="text"
          placeholder="Apartment, suite, unit, etc. (optional)"
          autoComplete="shipping-address-line2"
          required
          size="small"
          onChange={(e) => setAddressLine2(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 6 }}>
        <FormLabel htmlFor="city" required>
          City
        </FormLabel>
        <OutlinedInput
          id="city"
          name="city"
          type="text"
          placeholder="Johannesburg"
          autoComplete="City"
          required
          size="small"
          onChange={(e) => setCity(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 6 }}>
        <FormLabel htmlFor="zip" required>
          Zip / Postal code
        </FormLabel>
        <OutlinedInput
          id="zip"
          name="zip"
          type="text"
          placeholder="12345"
          autoComplete="shipping postal-code"
          required
          size="small"
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <Divider sx={{ marginTop: 3 }} textAlign="left">
          <Typography variant="h5">Emergency Contact</Typography>
        </Divider>
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="emergency-contact-name" required>
          Full name
        </FormLabel>
        <OutlinedInput
          id="emergency-contact-name"
          name="emergency-contact-name"
          type="text"
          required
          size="small"
          onChange={(e) => setEmergencyName(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="emergency-contact-number" required>
          Contact number
        </FormLabel>
        <OutlinedInput
          id="emergency-contact-number"
          name="emergency-contact-number"
          type="text"
          required
          size="small"
          onChange={(e) => setEmergencyNumber(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="emergency-contact-relationship" required>
          Relationship
        </FormLabel>
        <OutlinedInput
          id="emergency-contact-relationship"
          name="emergency-contact-relationship"
          type="text"
          required
          size="small"
          onChange={(e) => setEmergencyRelationTo(e.target.value)}
        />
        <FormHelperText>
          How is your emergency contact related to you?
        </FormHelperText>
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <Divider sx={{ marginTop: 3 }} textAlign="left">
          <Typography variant="h5">Parent (if under 18)</Typography>
        </Divider>
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="parent-name">Full name</FormLabel>
        <OutlinedInput
          id="parent-name"
          name="parent-name"
          type="text"
          size="small"
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="parent-number">Contact number</FormLabel>
        <OutlinedInput
          id="parent-number"
          name="parent-number"
          type="text"
          size="small"
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <Divider sx={{ marginTop: 3 }} textAlign="left">
          <Typography variant="h5">Medical Aid</Typography>
        </Divider>
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="scheme-name">Scheme name</FormLabel>
        <OutlinedInput
          id="scheme-name"
          name="scheme-name"
          type="text"
          size="small"
          onChange={(e) => setMedicalAidName(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12, md: 6 }}>
        <FormLabel htmlFor="membership-number">Membership Number</FormLabel>
        <OutlinedInput
          id="membership-number"
          name="membership-number"
          type="text"
          size="small"
          onChange={(e) => setMedicalAidNumber(e.target.value)}
        />
      </FormGrid>
    </Grid>
  );
};

export default AddressForm;
