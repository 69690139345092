import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

type InfoProps = {
  joiningFee?: number;
  totalDependants?: number;
  subName?: string;
  subCost?: number;
  minDependants?: number;
  alreadyMember?: boolean;
};

export default function Info({
  joiningFee = 0,
  totalDependants = 0,
  subName = '',
  subCost = 0,
  minDependants = 0,
  alreadyMember = false
}: InfoProps) {
  const totalJoiningFee = () => {
    return totalDependants > 0 && minDependants > 0
      ? (totalDependants + 1) * joiningFee
      : joiningFee;
  };

  const totalDueToday = () => {
    const dependantsTotal = minDependants > 0 ? subCost * totalDependants : 0;
    const joiningIncluded = !alreadyMember ? totalJoiningFee() : 0;
    return subCost + dependantsTotal + joiningIncluded;
  };

  const totalDueMonthly = () => {
    const joiningIncluded = !alreadyMember ? joiningFee : 0;
    return totalDueToday() - joiningIncluded;
  };

  const joiningFeeProduct =
    joiningFee && !alreadyMember
      ? [
          {
            name: 'Joining Fee',
            desc: 'Once off fee',
            price: `R${totalJoiningFee()}.00`
          }
        ]
      : [];

  const dependantsProduct =
    totalDependants > 0 && minDependants > 0
      ? [
          {
            name: 'Dependants',
            desc: 'Total x' + totalDependants,
            price: `R${totalDependants * subCost}.00`
          }
        ]
      : [];

  const packageProduct = subName
    ? [
        {
          name: 'Subscription Package',
          desc: subName,
          price: `R${subCost}.00`
        }
      ]
    : [];

  const products = [
    ...joiningFeeProduct,
    ...packageProduct,
    ...dependantsProduct
  ];

  return (
    <React.Fragment>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
        Total due today
      </Typography>
      <Typography variant="h4" gutterBottom>
        R{totalDueToday()}.00
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText
              sx={{ mr: 2 }}
              primary={product.name}
              secondary={product.desc}
            />
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              {product.price}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
        Monthly debit order
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
        gutterBottom
      >
        Total due from next month
      </Typography>
      <Typography variant="h4" gutterBottom>
        R{totalDueMonthly()}.00
      </Typography>
    </React.Fragment>
  );
}
