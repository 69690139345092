import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Subscriptions from '../../../components/Subscriptions';
import { Dependant, PackageType } from '../../../types/debit-order.type';

export const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

type IProps = {
  setData: (data: PackageType) => void;
  setDependants: (
    minDependantsRequired: number,
    dependants: Dependant[]
  ) => void;
};

const Packages: FC<IProps> = ({ setData, setDependants }) => {
  const [subscription, setSubscription] = useState<number>(2);
  const [subName, setSubName] = useState<string>('');
  const [subCost, setSubCost] = useState<number>(0);
  const [package_id, setPackageID] = useState<number>(2);
  const [minDependants, setMinDependants] = useState<number>(0);

  const data = {
    dependants: [{ name: '', contact_number: '', email_address: '' }]
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: data
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'dependants'
  });

  useEffect(() => {
    setData({
      subscription,
      package_id,
      dependants: getValues().dependants,
      subName,
      subCost,
      minDependants
    });

    setDependants(minDependants, fields);
  }, [subscription, package_id, minDependants, fields]);

  useEffect(() => {
    if (minDependants > 0) {
      let dependantFields = [];

      for (let i = 0; i < minDependants; i++) {
        dependantFields.push({
          name: '',
          email_address: '',
          contact_number: ''
        });
      }

      reset({
        dependants: dependantFields
      });
    }
  }, [package_id, minDependants]);

  const onSubmit = (data: any) => console.log(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <FormGrid size={{ xs: 12 }}>
          <Divider>
            <Typography variant="h4">Choose package type</Typography>
          </Divider>
        </FormGrid>
        <FormGrid size={{ xs: 12 }}>
          <Subscriptions
            setData={(packageId, subId, minDep, subsName, subsCost) => {
              setSubscription(subId);
              setPackageID(packageId);
              setMinDependants(minDep);
              setSubName(subsName);
              setSubCost(subsCost);
            }}
          />
        </FormGrid>

        {minDependants > 0 && (
          <FormGrid size={{ xs: 12 }}>
            <Divider
              sx={{
                marginBottom: 3,
                '.MuiDivider-wrapper': {
                  display: 'inline-flex',
                  gap: 2
                }
              }}
              textAlign="left"
            >
              <Typography variant="h5">Dependants</Typography>
              {minDependants > 1 && (
                <Button
                  onClick={() => {
                    append({
                      name: '',
                      email_address: '',
                      contact_number: ''
                    });
                  }}
                  variant="outlined"
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'inline-flex'
                    }
                  }}
                >
                  Add dependant
                </Button>
              )}
            </Divider>
            <Stack spacing={{ xs: 5, sm: 1 }}>
              {fields.map(
                ({ id, name, email_address, contact_number }, index) => {
                  return (
                    <Stack
                      key={id}
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={1}
                    >
                      <TextField
                        id={`dependant-fname-${index}`}
                        label="Fullname"
                        variant="outlined"
                        {...register(`dependants.${index}.name`)}
                        defaultValue={name}
                        size="small"
                        sx={{ minWidth: '40%' }}
                        required
                      />
                      <TextField
                        id={`dependant-email-${index}`}
                        label="Email"
                        variant="outlined"
                        {...register(`dependants.${index}.email_address`)}
                        defaultValue={email_address}
                        size="small"
                        required
                      />
                      <TextField
                        id={`dependant-phone-${index}`}
                        label="Contact number"
                        variant="outlined"
                        {...register(`dependants.${index}.contact_number`)}
                        defaultValue={contact_number}
                        size="small"
                        required
                      />
                      {minDependants > 1 && fields.length > 2 && (
                        <>
                          <IconButton
                            sx={{
                              display: {
                                xs: 'none',
                                md: 'block'
                              }
                            }}
                            onClick={() => remove(index)}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Button
                            sx={{
                              display: {
                                xs: 'inline-flex',
                                md: 'none'
                              },
                              alignSelf: 'flex-end'
                            }}
                            onClick={() => remove(index)}
                            variant="contained"
                            size="small"
                            color="error"
                          >
                            Remove <DeleteIcon />
                          </Button>
                        </>
                      )}
                    </Stack>
                  );
                }
              )}
              {/* Mobile button */}
              {minDependants > 1 && (
                <Button
                  sx={{
                    display: {
                      xs: 'inline-flex',
                      md: 'none'
                    },
                    fontWeight: 700
                  }}
                  onClick={() =>
                    append({
                      name: '',
                      email_address: '',
                      contact_number: ''
                    })
                  }
                  color="secondary"
                  variant="contained"
                  size="large"
                  startIcon={<PersonAddIcon />}
                >
                  Add Dependant
                </Button>
              )}
            </Stack>
          </FormGrid>
        )}
      </Grid>
    </form>
  );
};

export default Packages;
