import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PaymentForm from './components/PaymentForm';
import Review from './components/Review';
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormLabel
} from '@mui/material';
import AddressForm, { FormGrid } from './components/AddressForm';
import axios from 'axios';
import SignaturePad from 'react-signature-pad-wrapper';
import { DebitOrder, PackageType } from '../../types/debit-order.type';
import { Applicant, ApplicantApi } from '../../types/applicant.type';
import Packages from './components/Packages';
import Info from './components/Info';
import InfoMobile from './components/InfoMobile';
import { SETTINGS } from '../../constants/settings';

const steps = [
  'Personal details',
  'Subscription',
  'Debit order',
  'Review details'
];

type IProps = {};

const Checkout: React.FC<IProps> = () => {
  const [joiningFee, setJoiningFee] = React.useState<number>();
  const [activeStep, setActiveStep] = React.useState(0);
  const signaturePadRef = React.createRef<SignaturePad>();
  const [signature, setSignature] = React.useState<string>('');
  const [debitOrder, setDebitOrder] = React.useState<DebitOrder>();
  const [packageType, setPackageType] = React.useState<PackageType>({
    subscription: 2,
    package_id: 2,
    dependants: [],
    subName: '',
    subCost: 0,
    minDependants: 0
  });
  const [member, setMember] = React.useState<Applicant>();
  const [done, setDone] = React.useState<boolean>(false);
  const [alreadyMember, setAlreadyMember] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [dependantsError, setDependantsError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const getSignature = (step: number) => {
    // if (!signature) {
    const getSignature = signaturePadRef.current;

    if (getSignature) {
      setSignature(getSignature.toDataURL('image/svg+xml'));
    }
    // }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(activeStep + 1);
    getSignature(nextStep);
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
    getSignature(prevStep);
  };

  const getStepContent = (step: number) => {
    return (
      <>
        <div style={{ display: step === 0 ? 'block' : 'none' }}>
          <AddressForm
            setData={(user) => {
              setMember(user);
            }}
          />
        </div>
        <div style={{ display: step === 1 ? 'block' : 'none' }}>
          <Packages
            setData={(data) => setPackageType(data)}
            setDependants={(minDependantsRequired, dependants) => {
              let hasError = false;

              if (minDependantsRequired > 0) {
                dependants.map((item) => {
                  if (
                    !item.contact_number ||
                    !item.email_address ||
                    !item.name
                  ) {
                    hasError = true;
                  }
                });

                setDependantsError(hasError);
              }
            }}
          />
        </div>
        <div style={{ display: step === 2 ? 'block' : 'none' }}>
          <PaymentForm
            setData={(data) => {
              setDebitOrder(data);
            }}
            saveJoiningFee={(fee, member) => {
              setJoiningFee(fee);
              setAlreadyMember(member);
            }}
          />
        </div>
        <div style={{ display: step === 3 ? 'block' : 'none' }}>
          <Review />
        </div>
      </>
    );
  };

  // TODO: call api
  const handleSave = async () => {
    setSaving(true);

    if (debitOrder && member) {
      const data: ApplicantApi = {
        ...debitOrder,
        ...packageType,
        ...member,
        signature
      };

      await axios
        .post('new-member-signup', data)
        .then(async (response) => {
          if (response.status == 200) {
            setErrorMessage('');
            setDone(true);
            setSaving(false);
          }
        })
        .catch((error) => {
          const response = JSON.parse(error.request.response);
          alert('please fill in all fields! ' + response);
          setErrorMessage(response);
          setActiveStep(0);
          setSaving(false);
        });
    } else {
      alert('please fill in all fields!');
    }
  };

  const isDisabled = () => {
    return (
      (activeStep === steps.length - 1 &&
        !member?.email &&
        !member?.id_number &&
        !member?.title &&
        !member?.initials &&
        !member?.first_name &&
        !member?.last_name &&
        !member?.contact_number &&
        !member?.address_line_1 &&
        !member?.city &&
        !member?.postal_code &&
        !member?.emergency_contact_name &&
        !member?.emergency_contact_number &&
        !member?.emergency_contact_relation &&
        !debitOrder?.signed_at &&
        !debitOrder?.bank_account_holder &&
        !debitOrder?.account_number &&
        !debitOrder?.branch_code &&
        !debitOrder?.bank_name &&
        dependantsError) ||
      saving
    );
  };

  return (
    <>
      <Card
        sx={{
          display: { xs: 'flex', md: 'none' },
          width: '100%',
          position: 'sticky',
          borderRadius: 0,
          border: 'none',
          top: 0,
          zIndex: 2
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            background: '#000',
            color: '#fff',
            justifyContent: 'space-between',
            py: 1,
            ':last-child': {
              paddingBottom: 0.6
            }
          }}
        >
          <InfoMobile
            packageType={packageType}
            joiningFee={SETTINGS.joiningFee}
            alreadyMember={alreadyMember}
          />
        </CardContent>
      </Card>

      <Container maxWidth="xl">
        <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
          <Grid
            size={{ xs: 12, sm: 5, lg: 4 }}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              borderRight: { sm: 'none', md: '1px solid' },
              borderColor: { sm: 'none', md: 'divider' },
              alignItems: 'start',
              pt: { xs: 0, lg: 8 },
              px: 5,
              gap: 4,
              paddingRight: 10
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                gap: 4
              }}
            >
              <img
                style={{ alignSelf: 'flex-start' }}
                src="/assets/logo-invert.jpg"
                height={100}
                width="auto"
              />
              <div style={{ position: 'sticky', top: '2rem' }}>
                <Info
                  totalDependants={packageType.dependants.length}
                  joiningFee={SETTINGS.joiningFee}
                  subName={packageType.subName}
                  minDependants={packageType.minDependants}
                  subCost={packageType.subCost}
                  alreadyMember={alreadyMember}
                />
              </div>
            </Box>
          </Grid>
          <Grid
            size={{ sm: 12, md: 7, lg: 8 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              width: '100%',
              backgroundColor: { xs: 'transparent', sm: 'background.default' },
              alignItems: 'start',
              pt: { xs: 0, sm: 8 },
              pb: { xs: 6, sm: 8 },
              px: { xs: 0, sm: 10 },
              gap: { xs: 3, md: 5 }
            }}
          >
            {!done && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { sm: 'space-between', md: 'flex-end' },
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    flexGrow: 1
                  }}
                >
                  <Stepper
                    id="desktop-stepper"
                    activeStep={activeStep}
                    sx={{ width: '100%', height: 40 }}
                  >
                    {steps.map((label) => (
                      <Step
                        sx={{
                          ':first-child': { pl: 0 },
                          ':last-child': { pr: 0 }
                        }}
                        key={label}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
            )}
            {!done && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    width: '100%',
                    gap: { xs: 5, md: 'none' }
                  }}
                >
                  <Stepper
                    id="mobile-stepper"
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{ display: { sm: 'flex', md: 'none' } }}
                  >
                    {steps.map((label) => (
                      <Step
                        sx={{
                          ':first-child': { pl: 0 },
                          ':last-child': { pr: 0 },
                          '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } }
                        }}
                        key={label}
                      >
                        <StepLabel
                          sx={{
                            '.MuiStepLabel-labelContainer': { maxWidth: '70px' }
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {/* TODO: add a different screen */}
                  {activeStep === steps.length ? (
                    <>
                      {/* <Stack spacing={2} useFlexGap>
                <Typography variant="h1">📦</Typography>
                <Typography variant="h5">Thank you for your order!</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  Your order number is
                  <strong>&nbsp;#140396</strong>. We have emailed your order
                  confirmation and will update you once its shipped.
                </Typography>
                <Button
                  variant="contained"
                  sx={{ alignSelf: 'start', width: { xs: '100%', sm: 'auto' } }}
                >
                  Go to my orders
                </Button>
              </Stack> */}
                    </>
                  ) : (
                    <React.Fragment>
                      {getStepContent(activeStep)}
                      <div
                        style={{ display: activeStep !== 2 ? 'none' : 'block' }}
                      >
                        <FormGrid size={{ xs: 12 }}>
                          <FormLabel sx={{ marginBottom: '2px' }} required>
                            Signature
                          </FormLabel>
                          <Box
                            width={300}
                            height={100}
                            sx={{
                              border: 'solid 1px rgba(0, 0, 0, 0.23)',
                              borderRadius: '4px',
                              canvas: {
                                width: '100%'
                              }
                            }}
                          >
                            <SignaturePad
                              redrawOnResize
                              width={300}
                              height={100}
                              options={{
                                minWidth: 0.5,
                                maxWidth: 1,
                                penColor: 'rgb(0, 0, 0)'
                              }}
                              ref={signaturePadRef}
                            />
                          </Box>
                        </FormGrid>
                      </div>
                      <Box
                        sx={[
                          {
                            display: 'flex',
                            flexDirection: { sm: 'row' },
                            alignItems: 'end',
                            flexGrow: 1,
                            gap: 1,
                            pb: { xs: 5, sm: 0 },
                            mt: { xs: 2, sm: 0 }
                          },
                          activeStep !== 0
                            ? { justifyContent: 'space-between' }
                            : { justifyContent: 'flex-end' }
                        ]}
                      >
                        {activeStep !== 0 && (
                          <Button
                            startIcon={<ChevronLeftRoundedIcon />}
                            onClick={handleBack}
                            variant="text"
                            sx={{ display: { xs: 'none', sm: 'flex' } }}
                          >
                            Previous
                          </Button>
                        )}
                        {activeStep !== 0 && (
                          <Button
                            startIcon={<ChevronLeftRoundedIcon />}
                            onClick={handleBack}
                            variant="outlined"
                            fullWidth
                            sx={{ display: { xs: 'flex', sm: 'none' } }}
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          endIcon={<ChevronRightRoundedIcon />}
                          disabled={isDisabled()}
                          onClick={
                            activeStep === steps.length - 1
                              ? handleSave
                              : handleNext
                          }
                          sx={{ width: { xs: '100%', sm: 'fit-content' } }}
                        >
                          {activeStep === steps.length - 1
                            ? 'Sign up now'
                            : 'Next'}
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </>
            )}

            {done && (
              <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h5">
                  Dear {member?.first_name} {member?.last_name}
                </Typography>
                <Typography>
                  Welcome to the Elite Muaythai Gym Family! I am thrilled to
                  have you join us on this incredible journey of strength,
                  discipline, and self-improvement.
                </Typography>
                <Typography>
                  At Elite Muaythai Gym, we pride ourselves on creating a
                  supportive and inclusive environment where every member can
                  achieve their personal best. Whether you are a seasoned
                  fighter or just beginning your Muaythai journey, you will find
                  a community here that envourages and motivates each other to
                  reach new heights.
                </Typography>
                <Typography>
                  We at Elite are dedicated to helping you develop your skills,
                  build your confidence, and achieve your fitness goals. We
                  offer a variety of classes and training sessions tailored to
                  all levels, ensuring that everyone can progress at their own
                  pace while feeling challenged and inspired.
                </Typography>
                <Typography>
                  As a member of the Elite family, you will have access to a
                  wealth of knowledge from our seasoned coaches. We also host
                  regular events, workshops, and competitions to keep you
                  engaged and excited about your training.
                </Typography>
                <Typography>
                  Remember, the journey of a thousand miles begins with a single
                  step, and you have already taken that crucial first step by
                  joining us.
                </Typography>
                <Typography>
                  We are here to support you every step of the way, and we can't
                  wait to see the incredible progress you make.
                </Typography>
                <Typography>
                  Once again, welcome to Elite Muaythai Gym. We are honored to
                  have you with us and look forward to seeing you in the gym!
                </Typography>
                <Typography>
                  Best regards,
                  <br />
                  Kru David
                  <br />
                  Elite Muaythai Gym
                </Typography>
              </Box>
            )}

            <Backdrop
              sx={(theme) => ({
                color: '#fff',
                zIndex: theme.zIndex.drawer + 1
              })}
              open={saving}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Checkout;
